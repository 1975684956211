import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCompanyReviews, replyReviews } from '../../store/actions';
import TextArea from 'antd/lib/input/TextArea';
import StyledButton from '../../components/UI/btn';
import Modal from '../../components/UI/modal';

const CustomerPreview = () => {

    const dispatch = useDispatch() 
    const [showModal, setShowModal] =  useState(false)
    const [reviewId, setReviewId] =  useState('')
    const [reply, setReply] =  useState('')
    const [reviews, setReviews] = useState([])

    const fetchReview = async (key, page = 1) => {
        const res = await dispatch(
          getCompanyReviews()
        );
        setReviews(res.payload.data.reviews)
        if (!res.error) {
          console(res)
          setReviews(res.payload.data.reviews)
        }
      };

      useEffect(()=>{
        fetchReview()
      },[])

    const handleReplyReview = async () => {
        const res = await dispatch(
          replyReviews(
            {
              "reviewId":reviewId,
              "reply":reply
          }
          )
        );
        console.log(res)
        setReply('')
      };

      useEffect(()=>{
        fetchReview()
      },[])



  return (

    <div>
      <Modal
				show={showModal}
				close={() => setShowModal(false)}
			>

        <div className='space-y-6'>
            <p className="text-xl font-semibold">Reply Review</p>


          <TextArea onChange={(e)=>setReply(e.target.value)} className='py-[3rem]' rows={6} placeholder='Type your response'/>

          <div className="flex justify-center gap-2">
              <StyledButton
                raffle
                buttonStyle="btn--primary"
                buttonSize="btn--medium"
                className="text-white"
                buttonWidth={'100%'}
                onClick={handleReplyReview}
              >
                  Send  
              </StyledButton>
            </div>          
        </div>

			</Modal>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {
          reviews?.map((i, id)=>(
            <ReviewCard
              name={i.user.fullname}
              replied={i.hasReply}
              org={i.organisation.companyName}
              day={calculateDifference(i.createdAt)}
              rating={i.rating}
              review={i.comment}
              handleClick={()=>{
                setReviewId(i._id)
                setShowModal(true)
              }}
            />
          ))
        }
      </div>
    </div>
  )
}

export default CustomerPreview

const ImageRenderer = ({ n, imgUrl }) => {
  return (
    <div style={{ display: "flex", gap: "1px" }}>
      {Array.from({ length: n }).map((_, index) => (
        <img
        className='w-[15px] h-[15px]'
          key={index}
          src={imgUrl}
          alt={`Placeholder ${index + 1}`}
        />
      ))}
    </div>
  );
};

const ReviewCard = ({name, day, org, status, review, handleClick, replied, rating})=>{
    return(
        <div  className='bg-white rounded-lg shadow-lg p-3  flex flex-col justify-between'>
          <div className='space-y-2'>
            <div className='flex items-center justify-between'>
                <div>
                    <p className='font-bold text-[18px]'>{name}</p>
                    <div className='flex gap-2'>
                      <div className='flex gap-[1px]'>
                        <ImageRenderer n={rating} imgUrl={"/assets/images/star.png"}/>
                        <ImageRenderer n={5 - rating} imgUrl={"/assets/images/star-outline.png"}/>
                      </div>
                        
                        <span className='text-[12px]'>{day}days Ago</span>
                    </div>
                </div>
                <span  className={`${replied ? 'text-[#007200] bg-[#EAFFEA]':'text-[#AD3307] bg-[#FFECE5]'} text-[13px] px-2 rounded-2xl  font-semibold `}>{replied? 'Responded':'Unresponded'}</span>
            </div>
            <p className='text-[12px]  text-[#727272]'>Organization: <span className='font-semibold'>{org}</span></p>
            <p className='text-[#727272]'>{review}</p>            
          </div>

            <div className='py-2  border-t'>
              <button onClick={handleClick} className='text-primary font-semibold p-2'><img className='inline-flex mr-2' src="/assets/images/message.png" alt="" /> Reply to Review</button>
            </div>
        </div>
    )
}


function calculateDifference(targetDateStr) {
  const targetDate = new Date(targetDateStr); 
  const currentDate = new Date(); 

  const differenceMs = targetDate.getTime() - currentDate.getTime();

  const differenceInSeconds = Math.floor(differenceMs / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);


  return differenceInDays;
}
