import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFinancialCompleted,
  fetchFinancialOutstandings,
  fetchInvestmentPlans,
} from "../actions";
import { fetchRecentActivities } from "../actions/financialsActions";

const initialState = {
  completedPayment: null,
  outstandingPayment: null,
  investments:null,
  activities:null
};

const financialsSlice = createSlice({
  name: "financials",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFinancialCompleted.fulfilled](state, { payload }) {
      state.completedPayment = payload.data.invoices;
    },
    [fetchFinancialOutstandings.fulfilled](state, { payload }) {
      state.outstandingPayment = payload.data.invoices;
    },
    [fetchInvestmentPlans.fulfilled](state, { payload }) {
      state.investments = payload.data.plans;
    },
    [fetchRecentActivities.fulfilled](state, { payload }) {
      state.activities = payload.data.activities;
    },
  },
});

const { actions, reducer } = financialsSlice;
export const {} = actions;
export default reducer;
