import React, { useEffect, useState } from 'react';

const AccordionItem = ({ title, content, isOpen, toggle, permit, onItemClick, clickedIndex, getItemIndex }) => {

  
  return (
    <div className="border-b border-gray-200 rounded-md mb-2">
      <button
        className="w-full p-4 flex justify-between items-center focus:outline-none"
        onClick={toggle}
      >
        <div className='flex items-center gap-3 '>
            <span className="font-semibold">{title}</span>
        </div>
        <div className='flex items-center gap-3 '>
            <svg
            className={`w-6 h-6 ml-auto ${isOpen ? 'transform rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
            />
            </svg>          
        </div>

      </button>
      {isOpen && <div className="p-4 transition-all duration-500 flex flex-col text-left space-y-1">

      {content?.map(({ name, _id, value, wastepicker }, i) => {
            return (
              <span 
              onClick={() => {
                  onItemClick(name, _id, value, wastepicker)
                  getItemIndex(_id)
              }} 
              key={i} 
              className={`py-2 w-full cursor-pointer pl-8 rounded-[4px] ${clickedIndex.includes(_id) ? 'bg-[#f2f9f2] ' : ''}`}>
                {name}
              </span>
            );
          })}
        </div>}
    </div>
  );
};

const SetupAccordion = ({ items, permission, onItemClick, indexes }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [indexList, setIndexList] = useState(indexes);

  const toggleItem = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getIndex = (id) => {
    if(indexList.includes(id)){
      const new_indexList = indexList.filter(item => item !== id);
      setIndexList(new_indexList)
    }else{
      setIndexList([...indexList, id]); // Use setIndexList to update the state, and append the new id to the array
    }
  };

  return (
    <div>
      {items?.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.name}
          content={item.items}
          isOpen={openIndex === index}
          toggle={() => toggleItem(index)}
          permit={permission}
          onItemClick={onItemClick}
          clickedIndex={indexList}
          getItemIndex={getIndex}
        />
      ))}
    </div>
  );
};
export default SetupAccordion




