import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getOngoingUserSavings,
  userCompletedSchedule,
} from "../../../store/actions";
import {  Space } from "antd";
import Button from "../../../components/UI/button";
import Tabcontent from "../../../components/UI/TabContent";
import UserSavingsDetailedModal from "../../../components/UI/UserSavingsDetailedModal";

const SavingView = ({ match }) => {
  const [bodyData, setBodyData] = useState();
  const [completedBodyData, setCompletedBodyData] = useState();
  const [paginationData, setPaginationData] = useState();
  const [completedPaginationData, setCompletedPaginationData] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [rowInfo, setRowInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState('0');

  const dispatch = useDispatch();
  const {
    params: { id },
  } = match;

  // for payload
  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      userCompletedSchedule({
        page,
        key: key || "",
        id,
      })
    );

    if (!res.error) {
      const { schedules, ...paginationData } = res.payload.data;
      setBodyData(schedules);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      userCompletedSchedule({
        currentMonth: date,
        page,
        id,
      })
    );
    if (!res.error) {
      const { schedules, ...paginationData } = res.payload.data;
      setBodyData(schedules);

      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const fetchUserOngoingSavings= async (page = 1) => {

    const res = await dispatch(
      getOngoingUserSavings({
        ...payload,
        page,
        id
      })
    );
    if (!res.error) {
      console.log(res.payload.data)
      const {plans, pagination} = res.payload.data;
      setBodyData(plans);
      setPaginationData({ ...pagination, date: payload });
    }
  };
  const fetchUserCompletedSavings= async (page = 1) => {

    const res = await dispatch(
      getOngoingUserSavings({
        ...payload,
        page,
        id
      })
    );
    if (!res.error) {
      console.log(res.payload.data)
      const {plans} = res.payload.data;
      setCompletedBodyData(plans);
      // setOustandingPagination({ ...paginationData, date: payload });
    }
  };

  const onRefresh = () => {
    fetchUserOngoingSavings();
    fetchUserCompletedSavings();
  };

  const handleSwitch = (key) => {
		setView(key);
	  };

  useEffect(() => {
    onRefresh();
  }, []);

  const pages = [{ name: "Total Users", link: "/admin/total_users" }];
  const previous = [
    { name: "User Details", link: `/admin/user_details/${id}` },
  ];

	const data = [
		{
			title: "Ongoing",
			link: "Ongoing",
			data: bodyData,
			// filterHandler: handleTransactionFilter  ,
			// searchHandler: searchOrganizationsTransactions,
			fetch: fetchUserOngoingSavings,
			totalPages: paginationData?.totalPages,
			paginationData: paginationData,
			columns: [
				{
					title: "Date Created",
					dataIndex: "dateCreated",
					key: "dateCreated",
					render: (text, record) => <p>{text.split(' ')[0]}</p>
				},
				{
					title: "Name of Plan",
					dataIndex: "planName",
					key: "planName",
				},
				{
					title: "Type of Plan",
					dataIndex: "investmentType",
					key: "investmentType",
				},
				{
					title: "Maturity Date",
					dataIndex: "maturityDate",
					key: "maturityDate",
					// render: (text, record) => <p>{text.split(' ')[0]}</p>
				},

        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type=""
                onClick={() => {
                  setRowInfo(record);
                  setShowModal(true);
                }}
              >
                See More
              </Button>
            </Space>
          ),
        },

			],
		},
		{
			title: "Completed",
			link: "Completed",
			data: completedBodyData,
			// filterHandler: handleTransactionFilter  ,
			// searchHandler: searchOrganizationsTransactions,
			fetch: fetchUserCompletedSavings,
			totalPages: completedPaginationData?.totalPages,
			paginationData: completedPaginationData,
			columns: [
				{
					title: "Date Created",
					dataIndex: "dateCreated",
					key: "dateCreated",
					render: (text, record) => <p>{text.split(' ')[0]}</p>
				},
				{
					title: "Name of Plan",
					dataIndex: "planName",
					key: "planName",
				},
				{
					title: "Type of Plan",
					dataIndex: "investmentType",
					key: "investmentType",
				},
				{
					title: "Maturity Date",
					dataIndex: "maturityDate",
					key: "maturityDate",
					// render: (text, record) => <p>{text.split(' ')[0]}</p>
				},

        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type=""
                onClick={() => {
                  setRowInfo(record);
                  setShowModal(true);
                }}
              >
                See More
              </Button>
            </Space>
          ),
        },

			],
		},

	];
  return (
    <div>
      <UserSavingsDetailedModal
        showModal={showModal}
        setShowModal={setShowModal}
        data={rowInfo}
        userData={rowInfo}
        investment
      />

      {/* <UserContainer>
        <NavBarLeft>
          <BreadCrumb
            pages={pages}
            current="Pending Schedule"
            previous={previous}
          />
        </NavBarLeft>
      </UserContainer> */}

<Tabcontent
						data={data}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						onRefresh={onRefresh}
						onSwitch={handleSwitch}
					/>
    </div>
  );
};

export default SavingView;


