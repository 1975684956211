import React, { useState } from 'react';

const ImageUpload = ({ title, fieldName, onImageUpload }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [dragging, setDragging] = useState(false);

  const handleImageUpload = (file) => {
    if (
      file &&
      (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')
    ) {
      const fileExtension = file.name.split('.').pop().toLowerCase(); // Extract file extension
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
        if (onImageUpload) {
          onImageUpload(reader.result, fieldName, fileExtension); // Send file extension to parent
        }
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload a valid PNG, JPG, or JPEG image file.');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleImageUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);

    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={`w-72 h-48 border-2 border-dashed rounded-lg flex items-center justify-center text-center mb-4 transition-colors ${dragging ? 'bg-blue-100' : 'bg-white'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {imagePreview ? (
          <img src={imagePreview} alt="Preview" className="max-w-full max-h-full rounded-lg" />
        ) : (
          <p className="text-gray-500">Drag and drop an image here, or click to upload.</p>
        )}
        <input
          type="file"
          accept=".png, .jpg, .jpeg" 
          onChange={handleFileChange}
          className="hidden"
          id={fieldName}
        />
      </div>
      <label
        htmlFor={fieldName}
        className="inline-block px-4 py-2 bg-primary text-white rounded-md cursor-pointer hover:bg-blue-600"
      >
        Upload {title}
      </label>
    </div>
  );
};

export default ImageUpload;
