import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from ".";
import FinancialService from "../../services/financialService";
import { startLoad, stopLoad } from "../reducers/appSlice";

export const fetchFinancialSummaryNew = createAsyncThunk(
  "financials/newsummary",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialSummaryNew();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);


export const filterFinancialSummaryNew = createAsyncThunk(
  "financials/filtersummary",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    const {start, end} = data;
    try {
      const res = await FinancialService.FilterFinancialSummaryNew(start, end);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const filterOrgFinancialSummaryNew = createAsyncThunk(
  "financials/filternewsummary",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    const {start, end, id} = data;
    try {
      const res = await FinancialService.FilterOrgFinancialSummaryNew(start, end, id);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchOrgFinancialSummaryNew = createAsyncThunk(
  "financials/newsummary",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    const {id} = data;
    try {
      const res = await FinancialService.getOrgFinancialSummaryNew(id);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchFinancialSummary = createAsyncThunk(
  "financials/summary",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialSummary();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);


export const fetchOrgTransactions = createAsyncThunk(
  "financials/transactions",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getOrganizationsTransactions(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const searchOrgTransactions = createAsyncThunk(
  "financials/search_transactions",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.searchOrganizationsTransactions(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const searchOrgInvoice = createAsyncThunk(
  "financials/search_Invoice",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.searchOrganizationsInvoice(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const downloadAsPDF = createAsyncThunk(
  "financials/transactions",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.DownloadAsPDF(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchOrgInvoices = createAsyncThunk(
  "financials/transactions",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getOrganizationsInvoices(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const deleteOrgInvoice = createAsyncThunk(
  "financials/deleteInvoice",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.deleteOrganizationsInvoices(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchFinancialOrganizations = createAsyncThunk(
  "financials/organizations",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialOrganizations(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchFinancialOutstandings = createAsyncThunk(
  "financials/oustandings",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getOutstandingFinancials(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const fetchFinancialCompleted = createAsyncThunk(
  "financials/completed",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getCompletedFinancials(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchFinancialSearch = createAsyncThunk(
  "financials/search",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialsSearch(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchFinancialfilter = createAsyncThunk(
  "financials/filter",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialsFilter(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchTransactionfilter = createAsyncThunk(
  "financials/transaction-filter",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialTransactionFilter(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchInvoicefilter = createAsyncThunk(
  "financials/invoice-filter",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getFinancialsInvoiceFilter(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const completeFinancialPayment = createAsyncThunk(
  "financials/complete payment",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.completePayment(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const fetchTransactionInvoice = createAsyncThunk(
  "financials/fetch invoice",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getInvoice(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const downloadInvoice = createAsyncThunk(
  "financials/download invoice",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.downloadInvoice(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);

export const partners = createAsyncThunk(
  "get/partners",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.partners(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const partnersDetails = createAsyncThunk(
  "partners/details",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { id, page } = data;
      const res = await FinancialService.partnerDetail(id, page);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const partnersSearch = createAsyncThunk(
  "partners/search",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { id, page, key } = data;
      const res = await FinancialService.partnerSearch(id, page, key);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const partnersFilter = createAsyncThunk(
  "partners/filter",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { id, page, currentMonth } = data;
      const res = await FinancialService.partnerFilter(id, page, currentMonth);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const markPaid = createAsyncThunk(
  "markAsPaid/partners",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { id } = data;
      const res = await FinancialService.markAsPaid(id);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const searchPartner = createAsyncThunk(
  "search/partners",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { key } = data;
      const res = await FinancialService.searchPartners(key);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);


export const fetchHouseholdChart = createAsyncThunk(
  "finance/chart",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const {  year, page } = data;
      const res = await FinancialService.FetchHouseholdChart(
        year,
        page
      );
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
export const fetchHouseholdData = createAsyncThunk(
  "finance/chart-filter",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { start, end, page } = data;
      const res = await FinancialService.FetchHouseholdData(
        start,
        end,
        page
      );
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
export const setInvoiceStatus = createAsyncThunk(
  "finance/chart",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const { status, amount, id, page } = data;
      const res = await FinancialService.SetInvoiceStatus(
        status,
        id,
        amount,
        page
      );
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

// ACTIONS  FOR INVESTMENT MANAGEMENT

export const createInvestmentPlans = createAsyncThunk(
  "financials/investment_plans",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.CreateInvestmentPlans(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const editInvestmentPlans = createAsyncThunk(
  "financials/investment_plans",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.EditInvestmentPlans(data);
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchInvestmentPlans = createAsyncThunk(
  "financials/investment_plans",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getInvestmentPlans();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchRecentActivities = createAsyncThunk(
  "financials/investment_activities",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getInvestmentRecentActivities();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchPlanTypes = createAsyncThunk(
  "financials/plan_types",
  async (_, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getInvestmentPlanTypes();
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchSinglePlanData = createAsyncThunk(
  "financials/single_plan_data",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getSinglePlan(data);
      console.log(res)
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const fetchPlanData = createAsyncThunk(
  "financials/plan_data",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.getInvestmentPlanData(data);
      console.log(res)
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
export const deletPlan = createAsyncThunk(
  "financials/delete_plan",
  async (data, { dispatch }) => {
    dispatch(startLoad());
    try {
      const res = await FinancialService.deleteInvestmentPlan(data);
      console.log(res)
      return res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      // stop loading eventually
      dispatch(stopLoad());
    }
  }
);
