import {  Switch, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { FlexContainer, ModalTitle, TitleText } from "../styledElements";
import StyledButton from "./btn";
import Modal from "./modal";
import Button from "./button";
import { formatSelectOptions } from "../../utils";
// import { fetchCompanyCollectors } from "../../store/actions/dashboardAction";
import { useDispatch } from "react-redux";
// import { assignSchedule } from "../../store/actions/scheduleActions";
import FormInput from "../auth/FormInput";
import useForm from "../../hooks/useForm";
import Select from "./select";
import { createInvestmentPlans, editInvestmentPlans, fetchInvestmentPlans, fetchPlanTypes } from "../../store/actions/financialsActions";
import { IoTrashBin, IoTrashBinOutline } from "react-icons/io5";

const InfoWrapper = styled.div`
  ${tw`flex flex-wrap gap-10 gap-x-12 w-11/12 bg-[gray]`}
`;

const InfoUserWrapper = styled.div`
  ${tw`flex flex-wrap gap-10 gap-x-16 rounded-md`}
`;

const InfoItem = styled.div`
  ${tw`flex flex-col space-y-2`}
`;

const InfoTitle = styled.p`
  ${tw`font-semibold text-sm leading-[22px] text-secondary`}
`;
const TabTitle = styled.p`
  ${tw`font-semibold text-sm leading-[22px] text-[gray] my-4`}
`;
const InfoValue = styled.p`
  ${tw`font-bold text-base leading-[28px]`};
  color: ${(props) => (props.color ? props.color : "#464F54")};
  font-size: ${(props) => (props.userAgencies ? "smaller" : "")};
`;

const initData = [
  {
    title: "Aggregator's ID",
    value: "17288",
  },
  {
    title: "Name",
    value: "John Doe",
  },
  {
    title: "Phone Number",
    value: "01-26634622",
  },
  // {
  //   title: "Gender",
  //   value: "Male",
  // },
  {
    title: "Waste Category",
    value: ["Can", "Pet bottles", "Carton"],
  },
  {
    title: "Preferred Organization",
    value: "Plastic Solutions Ng",
  },
  {
    title: "LGA/LCDA",
    value: "Alimosho",
  },
  {
    title: "Date",
    value: "Jan 15, 2022",
  },
  {
    title: "Status",
    value: "Pending",
  },
  {
    title: "Accepted Pickup:",
    value: "12",
  },
  {
    title: "Missed Pickup",
    value: "2",
  },
  {
    title: "No. of Trips Completed",
    value: "0",
  },
  {
    title: "Recycler No",
    value: "01-26634622",
  },
];

const documentsFormEntries = {
  invest_type: {
    label: "Type of Investment",
    optionIdx: null,
    type: "select",
    options: [],
    rules: [(v) => !!v || "Investment type is required"],
  },
  invest_name: {
    label: "Investment Name",
    value: "",
    placeholder: "Investment name",
    rules: [(v) => !!v || "Investment name is required"],
  },
};
const flexFormEntries = {
  description: {
    label: "Description",
    value: "",
    placeholder: "Investment description",
    rules: [(v) => !!v || "Investment description is required"],
  },
  interest_rate: {
    label: "Interest Rate (%)",
    value: "",
    placeholder: "Enter Interest Rate",
    rules: [(v) => !!v || "Interest rate is required"],
  },

  // min_withdrawal: {
  //   label: "Minimum withdrawals (per month)",
  //   value: "",
  //   placeholder: "Investment name",
  //   rules: [(v) => !!v || "Investment name is required"],
  // },
  // days_between_withdrawal: {
  //   label: "Maximum Days between withdrawals",
  //   value: "",
  //   placeholder: "Investment name",
  //   rules: [(v) => !!v || "Investment name is required"],
  // },

};
const savingFormEntries = {
  description: {
    label: "Description",
    value: "",
    placeholder: "Investment name",
    rules: [(v) => !!v || "Investment description is required"],
  },
  min_investment: {
    label: "Enter Minimum investment amount",
    value: "",
    placeholder: "E.g 5000",
    rules: [(v) => !!v || "min investment is required"],
  },


};

const Pointer = styled.div`
  ${tw`h-[5px] w-[5px] rounded-full bg-red-500 mr-2`};
  box-shadow: ${(props) =>
    props.color ? props.color : "0 0 0 3px rgb(217 144 144)"};
  background-color: ${(props) => (props.color ? props.color : "white")};
`;

const CreateInvestment = ({
  data = initData,
  showModal = false,
  setShowModal = {},
  onRefresh,
  userData,
  action,
  plan_id
}) => {
  const renderStatus = (text) => {
    let className = "";
    if (text === "successful") {
      className = "text-secondary rounded-sm bg-secondary";
    } else if (text === "failed") {
      className = "text-red-400 bg-red-200";
    } else {
      className = "text-blue-400 bg-blue-200";
    }
    return (
      <p className={`${className} font-bold py-[3px] w-fit px-2 text-sm`}>
        <span className="flex items-center">
          <Pointer
            color={
              text === "successful"
                ? "green"
                : text === "failed"
                ? "red"
                : "blue"
            }
          />{" "}
          {text}
        </span>
      </p>
    );
  };

  const {
    setValue: setDocVal,
    errorMsgs: docErrMsg,
    formValues: docFormValues,
    isValid: isValidDoc,
    clearForm: resetDocForm
  } = useForm(documentsFormEntries);
  const {
    setValue: setFlexVal,
    errorMsgs: flexErrMsg,
    formValues: flexFormValues,
    isValid: isValidFlex,
    clearForm: resetFlexForm
  } = useForm(flexFormEntries);
  const {
    setValue: setSaveVal,
    errorMsgs: saveErrMsg,
    formValues: saveFormValues,
    isValid: isValidSave,
    clearForm: resetSavingsForm
  } = useForm(savingFormEntries);


  const dispatch = useDispatch();

  const [hubList, setHubList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [orgId, setOrgId] = useState('')
  const [investType, setInvestType] = useState('flex-saving')
  const [error, setError] = useState(false)
  const [showPostAction, setShowPostAction] = useState(false)
  const [planList, setPlanList] = useState([{
                                            "interest":0,
                                            "mindurationinDays":0,
                                            "maxdurationinDays":0
                                        }]);   
  const [penalty, setPenalty] = useState('') 
  const [allowBreak, setAllowBreak] =  useState(false)

  const onChangeBreak = (checked) => {
    // console.log(checked)
    setAllowBreak(checked)
  };
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };


  const fetchInvestmentType = async (page = 1) => {
		
		const res = await dispatch(fetchPlanTypes());
		if (!res.error) {
      // console.log(res)
      setTypeList(res.payload.data)
      
		}else{
		  console.log(res.error)
		}

	};

  useEffect(()=>{
    if(typeList){
      const typeOptions = formatSelectOptions(typeList);
      // console.log(typeOptions)
    }
  }, [typeList])


  const List = [
    {
      text:'Flex Saving',
      value:'flex-saving'
    },
    {
      text:'Saving',
      value:'saving'
    },
  ]


  useEffect(()=>{
    if(userData){
      const idx =List.findIndex(i => i.value === userData.investmentType)
      documentsFormEntries.invest_type.optionIdx = idx
      docFormValues.invest_name = userData.name
      if (userData.investmentType === 'saving'){
        saveFormValues.description = userData.description
        saveFormValues.min_investment = userData.minInvestmentAmount
        setPenalty(userData.breakFee)
        setAllowBreak(userData.allowBreak)
        setPlanList(userData.duration)
        setInvestType('saving')
      }
      if (userData.investmentType === 'flex-saving'){
        flexFormValues.description = userData.description
        flexFormValues.min_investment= userData.minInvestmentAmount
        flexFormValues.interest_rate= userData.interestRate
        setInvestType('flex-saving')
      }
      
    }
  }, [userData])

  documentsFormEntries.invest_type.options = List;

  const capitalize = (text) =>
    text?.substring(0, 1).toUpperCase() + text?.slice(1).toLowerCase();

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const handleResetForm = () =>{
    resetDocForm()
    resetSavingsForm()
    resetFlexForm()
  }

  const handleSubmit = () => {

    const real_cat =  Object.entries(docFormValues).map(([key, input]) => ({
      name: key,
      price: +input,
    }))

    const orgFormData = investType === 'saving' ?{
      "name":docFormValues.invest_name,
      "description":saveFormValues.description,
      "minInvestmentAmount":Number(saveFormValues.min_investment),
      "allowBreak":true,
      "breakFee":Number(penalty),
      "showOnHouseholdPage":true,
      "showOnWastePickerPage":true,
      "investmentType":"saving",
      "duration":planList
    }: {
        "name":docFormValues.invest_name,
        "description":flexFormValues.description,
        "minInvestmentAmount":3000,
        "interestRate":Number(flexFormValues.interest_rate),
        "allowBreak":false,
        "breakFee":0,
        "showOnHouseholdPage":true,
        "showOnWastePickerPage":true,
        "investmentType":"flex-saving"
    };
    if (action ==='edit'){
      dispatch(editInvestmentPlans({id:plan_id, param:orgFormData}))
      .unwrap()
      .then(() => {
        setShowModal(false)
        setError(false)
        setShowPostAction(true)
        dispatch(fetchInvestmentPlans())
        // history.push("/admin/financials/investment");
      });
    }else{
      dispatch(createInvestmentPlans(orgFormData))
      .unwrap()
      .then(() => {
        setShowModal(false)
        setError(false)
        setShowPostAction(true)
        handleResetForm()
      }).catch((error)=>{
        console.log(error)
        setError(error)
        setShowPostAction(true)
        handleResetForm()
      }
      );
    }

  };

  useEffect(()=>{
    fetchInvestmentType()
  }, [])

  useEffect(()=>{
    if (docFormValues.invest_type)setInvestType(docFormValues.invest_type)
  }, [docFormValues.invest_type])

  const IncreasePlanCount = () => {
    setPlanList([...planList, {
                              "interest":0,
                              "mindurationinDays":0,
                              "maxdurationinDays":0
                          }]);
};

const DecreasePlanCount = (index) => {
        setPlanList((prevItems) => prevItems.filter((_, i) => i !== index));

};
    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPlanList = [...planList];
        updatedPlanList[index] = { ...updatedPlanList[index], [name]: Number(value) };
        setPlanList(updatedPlanList);
    };


  return (

    <>
      <Modal
        show={showPostAction}
        close={() => {
          setShowPostAction(false);
        }}
        type={error? 'postAction' : "dialog"}
        color={error && "#F5000F"}
      >
        <div className="w-full flex flex-col gap-10 p-12 justify-center items-center">
          {!error && <img src="/assets/images/mark.png" className='w-[100px]' alt="done" />}
          <p className="font-semibold text-[18px] text-center">{!error ? `Plan ${action === 'edit'?'Edited':'Created'} Successfully` :  'Something went wrong, Please try again !'}</p>  
          {
            !error && 
            <Button
            width=""
            onClick={()=>{setShowPostAction(false)}}
            // disabled={!isValid || !isValidDoc || !isValidCat}
          >
            Continue
          </Button>
          }
        </div>        
      </Modal>


      <Modal show={showModal} close={() => setShowModal(false)} width="48rem">
      <ModalTitle>
        <TitleText className="">{action ==='edit'?'Edit':'Create a'} Plan</TitleText>
        <StyledButton
          buttonSize="btn--medium"
          onClick={() => setShowModal(false)}
        >
          Close
        </StyledButton>
      </ModalTitle>

      <div>

      {/* <TabTitle>Select who to Assign Schedule to</TabTitle> */}

      {/* <InfoUserWrapper>

        <div className="w-full grid grid-cols-2 gap-8">
          <div className="">
            <p className="font-bold">Type of Investment</p>
            <Select
              className="assign-select w-full"
              showSearch
              placeholder="Select Collector"
              optionFilterProp="label"
              onChange={onHubChange}
              onSearch={onSearch}
              options={hubList}
            />        
          </div>        
          <div>
            {
              hubList && (
                <>
                <p>Select Agent</p>
                
                <Select
                  showSearch
                  className="assign-select w-full"
                  placeholder="Select Collector"
                  optionFilterProp="label"
                  onChange={onCollectorChange}
                  onSearch={onSearch}
                  options={collectorList}
                />                   
                </>
                  
              )
            }
   
          </div>        
        </div>        
      </InfoUserWrapper> */}

<div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
            {Object.entries(documentsFormEntries).map(([key, input]) => {
              switch (input.type) {
                case "select":
                  return (
                    <Select
                      key={key}
                      width="100%"
                      height="3.5rem"
                      plain={true}
                      options={input.options}
                      label={input.label}
                      title={input.label}
                      changeHandler={(v) => setDocVal(key, v)}
                      optionIdx={input.optionIdx !== null && input.optionIdx}
                      disabled={input.disabled}
                      checkbox={input.checkbox}
                    />
                  );

                default:
                  return (
                    <FormInput
                      placeholder={input.placeholder}
                      type={input.type}
                      label={input.label}
                      key={key}
                      changeHandler={(e) => setDocVal(key, e.target.value)}
                      errorMsg={docErrMsg[key]}
                      value={docFormValues[key]}
                      disabled={input.disabled}
                    />
                  );
              }
            })}
            {investType === 'flex-saving' && Object.entries(flexFormEntries).map(([key, input]) => {
              switch (input.type) {
                case "select":
                  return (
                    <Select
                      key={key}
                      width="100%"
                      height="3.5rem"
                      plain={true}
                      options={input.options}
                      label={input.label}
                      title={input.label}
                      changeHandler={(v) => setFlexVal(key, v)}
                      optionIdx={input.optionIdx !== null && input.optionIdx}
                      disabled={input.disabled}
                      checkbox={input.checkbox}
                    />
                  );

                default:
                  return (
                    <FormInput
                      placeholder={input.placeholder}
                      type={input.type}
                      label={input.label}
                      key={key}
                      changeHandler={(e) => setFlexVal(key, e.target.value)}
                      errorMsg={flexErrMsg[key]}
                      value={flexFormValues[key]}
                      disabled={input.disabled}
                    />
                  );
              }
            })}
            {
              investType === 'saving' &&
                Object.entries(savingFormEntries).map(([key, input]) => {
                  switch (input.type) {
                    case "select":
                      return (
                        <Select
                          key={key}
                          width="100%"
                          height="3.5rem"
                          plain={true}
                          options={input.options}
                          label={input.label}
                          title={input.label}
                          changeHandler={(v) => setSaveVal(key, v)}
                          optionIdx={input.optionIdx !== null && input.optionIdx}
                          disabled={input.disabled}
                          checkbox={input.checkbox}
                        />
                      );

                    default:
                      return (
                        <FormInput
                          placeholder={input.placeholder}
                          type={input.type}
                          label={input.label}
                          key={key}
                          changeHandler={(e) => setSaveVal(key, e.target.value)}
                          errorMsg={saveErrMsg[key]}
                          value={saveFormValues[key]}
                          disabled={input.disabled}
                        />
                      );
                  }
                })            
            }

          </div>


            {
              investType === 'saving' &&
              <div>
                <div className="flex justify-between items-center">
                  <p className="font-[700] text-[24px] text-[#464F54]">Set Interest Duration</p>
                  <div className="font-[700] text-[14px] text-[#464F54]"><span className="mr-2">Allow interest on Collectors</span><Switch/></div>
                  
                </div>
                <span>You can add more duration to this plan</span>
                <div className="mt-6">
                  {
                    planList.map((i, id)=>{

                      return(
                        <div className="flex items-center gap-3">
                              <div className="grid gap-2 grid-cols-3">
                              <FormInput
                                name='mindurationinDays'
                                placeholder={'E.g 10days'}
                                type={'text'}
                                label={'Minimum Duration (days)'}
                                changeHandler={(e) => handleItemChange(id, e)}
                                errorMsg={''}
                                value={i.mindurationinDays}
                              />
                              <FormInput
                                name='maxdurationinDays'
                                placeholder={'E.g 10days'}
                                type={'text'}
                                label={'Maximum Duration (days)'}
                                changeHandler={(e) => handleItemChange(id, e)}
                                errorMsg={''}
                                value={i.maxdurationinDays}
                                
                              />
                              <FormInput
                                name='interest'
                                placeholder={'E.g 10 %'}
                                type={'text'}
                                label={'% Interest PA'}
                                changeHandler={(e) => handleItemChange(id, e)}
                                errorMsg={''}
                                value={i.interest}
                                
                              />
                            </div>
                            <div className="flex gap-2">
                              <button onClick={()=>DecreasePlanCount(id)}>{planList.length>1 && <IoTrashBinOutline size={20} color="red"/>} </button>
                              <button onClick={IncreasePlanCount}>{id === planList.length-1 && <img src="/assets/images/plus-circle.png" className="w-[25px]" alt="" />}</button>
                            </div>
                          
                        </div>

                      )
                    })
                  }
                  
                  <div><span  className="font-[700] text-[14px] text-[#464F54] mr-2 ">Allow Early withdrawal</span><Switch checked={allowBreak} onChange={onChangeBreak}/></div>
                  <div className="grid gap-2 grid-cols-2 mt-4">
                    <FormInput
                      placeholder={'10%'}
                      type={'text'}
                      label={'Penalty Fee (In percent %)'}
                      changeHandler={(e) => {setPenalty(e.target.value)}}
                      errorMsg={''}
                      value={penalty}
                                  
                    /> 
                      <div className="mt-6 w-full flex justify-end ">
                        {
                                <Button
                                    width=""
                                    onClick={handleSubmit}
                                    disabled={!isValidDoc || !isValidSave}
                                  >
                                    {action === 'edit'?'Edit':'Add'} Plan
                                  </Button>
                          }
                      
                      </div>                  
                  </div>


                </div>
              </div>              
            }
            {
              investType === 'flex-saving' &&

                      <div className="mt-6 w-full flex justify-end ">
                        {
                                <Button
                                    width=""
                                    onClick={handleSubmit}
                                    disabled={!isValidDoc || !isValidFlex }
                                  >
                                    {action === 'edit'?'Edit':'Add'} Plan
                                  </Button>
                          }
                      
                      </div>                  
            
            }
      </div>
    </Modal>
    </>

  );
};

export default CreateInvestment;


;