import React, { useEffect, useState } from 'react'
import StyledButton from '../../../components/UI/btn'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInvestmentPlans, fetchRecentActivities } from '../../../store/actions/financialsActions'
import moment from 'moment'
import CreateInvestment from '../../../components/UI/CreateInvestment'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const InvestmentView = () => {
  const [hasData, setHasData] = useState(false)
  const [hasActivity, setHasActivity] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  // const [investments, setInvestments] = useState([])
  // const [recentActivities, setRecentAtivities] = useState([])
  const dispatch = useDispatch()
  const {investments, activities} = useSelector((state)=>state.financials)


  const fetchAll = async (page = 1) => {
    const res = await dispatch(fetchInvestmentPlans());

    if (!res.error && res.payload.data.plans.length > 0) {
      // console.log(res.payload.data.plans)
      // setInvestments(res.payload.data.plans)
      setHasData(true)
    }else{
      console.log(res.error)
    }
  };

  const fetchActivities = async (page = 1) => {
    const res = await dispatch(fetchRecentActivities());
      
    if (!res.error) {
      // console.log(res)
      // setRecentAtivities(res.payload.data.activities)
      setHasActivity(true)
    }else{
      console.log(res.error)
    }
  };


  useEffect(() => {
    fetchAll();
    fetchActivities()
  }, []);
  useEffect(() => {
    if(!investments){
      fetchAll();      
    }
    fetchActivities()
  }, [investments]);


  return (
    <>
    <CreateInvestment
      showModal={openCreate}
      setShowModal={setOpenCreate}
    />
    {
      hasData?
       <div className='space-y-8'>

          <div>
              <StyledButton
                  buttonStyle="btn--primary--solid"
                  buttonSize="btn--medium"
                  className="flex justify-between items-center"
                  onClick={()=>setOpenCreate(true)}
                >
                <img src="/assets/images/coinIcon.svg" alt="" /> 
                  Add More Plans
                </StyledButton>
          </div>

          <div className='grid grid-cols-3 gap-6'>
            {
              investments?.map((i, idx)=> {
                const percentage = i?.duration.map((dur)=> dur.interest)
                const sum_percent = i.duration.reduce((acc, item)=>acc + item.interest, 0)
                return(
                  <PlanCard
                    key={idx}
                    type={i.investmentType}
                    id ={i.id}
                    planName = {i.name}
                    planDescription={i.description}
                    percentage= {i.duration.slice(-1)[0].interest}
                  />                  
                )
              }

              )
            }
          </div>

          <div className='flex gap-6 w-full '>
            <div className='w-[80%] bg-white p-4 rounded-lg'>
            {
              hasActivity?
              <>
                  {
                    activities?.slice(0,6).map((i, id)=>(
                      <div key={id} className='border-b py-2 flex justify-between gap-8'>
                        
                        <div className='flex items-center gap-3'>
                          <div className='h-[30px] w-[30px] bg-primary rounded-full'></div>
                          <span>{i.details}</span>
                        </div>
                        <div className='flex gap-3'>
                          <span className='text-[#667185]'>{moment(i?.createdAt).format("DD/MM/YYYY")}</span>
                          <span className='text-[#667185]'>{moment(i?.createdAt).format("h:mmA")}</span>
                        </div>
                      </div>
                    ))
                  }               
              </>
    :   
                <p className='font-semibold text-[18px] w-[70%] text-center'>No Record Found</p>
            }

            </div>
            <div className='bg-[#E7F6EC] flex flex-col justify-center p-6'>
              <img src="/assets/images/wallet-minus.png" className='w-[100px]' alt="" />
              <p className='font-semibold text-[18px] w-[70%]'>Create, manage and view all Investment plans easily</p>
            </div>
            
          </div>

        </div>  
      :
        <div className='flex w-full h-[80vh] px-[3rem] justify-center items-center '>
          <div  className='w-full bg-white p-[4rem] rounded-lg flex justify-center items-center flex-col gap-4'>
            <div className='flex justify-center items-center flex-col'>
              <img src="/assets/images/Moneytree.png" alt="" />
              <h3 className='font-bold text-[16px]'>You don’t have any investment plan yet</h3>
              <p>Tap the button below to create a plan</p>
            </div>
            <StyledButton
                buttonStyle="btn--primary--solid"
                buttonSize="btn--medium"
                className="flex justify-between items-center"
                onClick={()=>setOpenCreate(true)}
              >
              <img src="/assets/images/coinIcon.svg" alt="" /> 
                Create Plan
              </StyledButton>
            
          </div>
        
      </div>           
    }

  
    </>

  )
}

export default InvestmentView


const PlanCard = ({planName, imgUrl, planDescription, percentage, type, id}) =>{
  const path = type === 'saving'? `/admin/investments/plan-history/${id}` : `/admin/investments/plan-history/${id}` 
  return(
    <Link to={{
        pathname: path,
        state: type,
      }} className='bg-white rounded-lg shadow-sm p-3 flex flex-col justify-between gap-2'>
      <div className='flex items-center justify-between w-full'>
        <img src='/assets/images/investPlanIcon.png' alt="" />
        <span className='text-primary bg-[#E7F6EC] rounded-2xl px-1'><img src="/assets/images/investPlanStar.png" className='inline-flex mr-2' alt="" />{type}</span>
      </div>
      <div>
        <h2 className='text-[#101928] font-bold text-[28px]'>{planName}</h2>
        <div  className='flex justify-between  items-center'>
          <span className='text-[#667185] text-[13px]'>{planDescription}</span>
          
        </div>
      </div>
      <div className='flex justify-between items-center py-3 border-t '>
        <span className='text-[#667185] text-[12px]'>Annual Returns</span>
        <span className='text-primary text-[14px] font-semibold'>{percentage}%</span>
      </div>
    </Link>
  )
}
