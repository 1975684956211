import baseAxios from "../core/api/axios/baseAxios";
import investmentBaseAxios from "../core/api/axios/investmentBaseAxios"
import newBaseAxios from "../core/api/axios/newBaseAxios";

class AppService {
  static async uploadFile(base64_data) {
    const fileData = new FormData();
    fileData.append("file", `data:image/png;base64,${base64_data}`);
    fileData.append("upload_preset", "live_report");
    const res = await baseAxios.post(
      "https://api.cloudinary.com/v1_1/dksyt7yvf/upload",
      fileData
    );
    return res?.data || res;
  }

  static async editCompanyProfile(data) {
    const res = await baseAxios.post("/api/admin/login", data);
    return res?.data || res;
  }

  static async getAllAdmins() {
    const res = await baseAxios.get("/api/get/all/admins");
    return res?.data || res;
  }
  static async GetCompanyReviews(data) {
    const res = await newBaseAxios.get("/v2/admin/company/reviews?start=2010-01-01&end=2025-01-01");
    return res?.data || res;
  }
  static async ReplyReviews(data) {
    const res = await newBaseAxios.post("/v2/admin/company/review/reply", data);
    return res?.data || res;
  }
  static async exportCSV(data) {
    try {
      const res = await investmentBaseAxios.get(
        `/v1/dashboard/download/${data.id}?isDownloadable=true`,
        {
          responseType: 'blob', 
        }
      );
  
      const blob = new Blob([res.data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = 'exported-file.csv'; 
  
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
  
      return res?.data || res;
    } catch (error) {
      console.error('Error downloading the file:', error);
      throw error;
    }
  }
  
}

export default AppService;
