import { Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Tabcontent from "../../../components/UI/TabContent";
import StyledButton from "../../../components/UI/btn";
import tw from "twin.macro";
import { StatusTag, capitalize, formatAmount, getMonthYear } from "../../../utils";
import { FinancialsCards } from "../../../utils/data";
import BreadCrumb from "../../../components/UI/breadCrumbs";
import { PointCard } from "..";
import { deletPlan, fetchPlanData, fetchSinglePlanData } from "../../../store/actions/financialsActions";
import PlanDetailModal from "../../../components/UI/PlanDetailModal";
import DeleteModal from "../../../components/common/DeleteModal";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CreateInvestment from "../../../components/UI/CreateInvestment";

const invoicebaseURL = process.env.REACT_APP_NEW_TEST_INVOICE_URL;

const WastePickerContainer = styled.div`
	display: grid;
	grid-template-coloumns: auto 1fr;
	gap: 20px;
`;

export const UserContainer = styled.div`
  //   display: grid;
  //   grid-template-coloumns: auto 1fr;
  margin-bottom: 20px;
  display: grid;
`;

export const NavBarLeft = styled.div`
  ${tw`flex justify-between`}

  .text {
    font-size: 15px;
    color: "#0e0e0e";
  }
`;

const ModalBackground = styled.div`
  width: 255px;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: absolute;
  border-radius: 10px;
  padding-bottom: 10px;
  z-index: 99999;
  left: 0;
  top: 70%;

  modalContainer {
    position: relative;
  }
`;

const GenerateInvoice = styled.div`
  margin-left: -10px !important;
  color: red;
`;

export const PartialLinkText = ({title})=>{
	const titleText = capitalize(title)
	return(
	  <p className="text-[#B67A12] font-Rale font-[400] text-[14px] underline">{titleText}</p>
	)
  }

const PlanHistory = ({ match }) => {
	const user_claims = JSON.parse(localStorage.getItem('Claims'))
	const location = useLocation()
	const dispatch = useDispatch()
	const history = useHistory()
	const type = location.state
    const {
        params: { id },
      } = match;

	  const d = new Date();
	  d.setDate(d.getDate());
	  const payload = {
		  start: "2010-01-01",
		  end: d,
	  };
	/****************************
	 *
	 * states and hooks
	 *
	 ****************************/
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableBody, setTableBody] = useState([]);
	const [tableBody2, setTableBody2] = useState([]);
	const [outstandingPagination, setOustandingPagination] = useState();
	const [invoicePagination, setInvoicePagination] = useState();
	const [showPostModal, setPostModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showDetailModal, setShowDetailModal] = useState(false);
	const [showPrompt, setPrompt] = useState(false);
	const [summaryData, setSummaryData] = useState();
	const [showReceiptModal, setReceiptModal] = useState();
	const [showSetStatus, setShowSetStatus] = useState(false);
	const [payStatus, setPayStatus] = useState(null);
	const [openPayStatus, setOpenPayStatus] = useState(false);
	const [showDeleteModal, setDeleteModal] = useState(false);
	const [openCreate, setOpenCreate] =  useState(false)
	const [planData, setPlanData] =  useState()
	const [orgData, setOrgData] = useState({});
	const [view, setView] = useState('0')
	const [selectedRecord, setSelectedRecord] = useState("")


	const fetchInvestmentPlanData = async (page = 1) => {

		const res = await dispatch(fetchPlanData({id: id}));
		if (!res.error) {
			console.log(res)
			const {dashboardStats, individualUserStats, pagination} = res.payload.data
			setSummaryData(dashboardStats)
			setTableBody(individualUserStats)
			setOustandingPagination({ ...pagination, date: payload });
			
		}else{
		  console.log(res.error)
		}

	};
	const fetchPlanDetails = async (page = 1) => {

		const res = await dispatch(fetchSinglePlanData({id: id}));
		if (!res.error) {
			console.log(res.payload.data)
			setPlanData(res.payload.data)
			// const {dashboardStats, individualUserStats, ...pagination} = res.payload.data
			// setSummaryData(dashboardStats)
			// setTableBody(individualUserStats)
			
		}else{
		  console.log(res.error)
		}

	};
	const handleDeleteInvestmentPlan = async (page = 1) => {

		const res = await dispatch(deletPlan({id: id}));
		if (!res.error) {
			console.log(res)
			history.push("/admin/financials/investment")
			
		}else{
		  console.log(res.error)
		}

	};

	const fetchOrganizationsTransactions = async (page = 1) => {
	};
	const searchOrganizationsTransactions = async (key, page = 1) => {


	};



	useEffect(()=>{
		fetchPlanDetails()
		fetchInvestmentPlanData()
	},[])



	/****************************
	 *
	 * handlers and utils functions
	 *
	 ****************************/

	const handleTransactionFilter = async (date, page = 1) => {

	};


	const handleSwitch = (key) => {
		setView(key);
	  };



	const data = [
		{
			// title: "Transaction History",
			link: "Transaction History",
			data: tableBody,
			filterHandler: handleTransactionFilter  ,
			searchHandler: searchOrganizationsTransactions,
			fetch: fetchOrganizationsTransactions,
			totalPages: outstandingPagination?.totalPages,
			paginationData: outstandingPagination,
			url:`/v1/dashboard/dashboard-stats/${id}?isDownloadable=true`,
			columns: type === 'saving'?[

				{
					title: "Username",
					dataIndex: "username",
					key: "username",
				},
				{
					title: "Amount Saved",
					dataIndex: "amountInvested",
					key: "amountInvested",
				},
				{
					title: "Amount Withdrawn",
					dataIndex: "totalAmountWithdrawn",
					key: "totalAmountWithdrawn",
				},
				{
					title: "No of Times Withdrawn",
					dataIndex: "timesWithdrawn",
					key: "timesWithdrawn",
				},

		
					{
						title: "Penalties",
						dataIndex: "penalties",
						key: "penalties",
					},
					{
						title: "Progress %",
						dataIndex: "savingProgress",
						key: "savingProgress",
					},
			
				{
					title: "Action",
					dataIndex: "action",
					key: "action",
					render: (text, record) => {
					  return (
						<Space size="middle">
							<StyledButton
							  type=""
							  buttonStyle="btn--primary--outline"
							  buttonSize="btn--small"
							  onClick={()=>{
								setSelectedRecord(record)
								setShowDetailModal(true)
								
							  }}
							>
							  See More
							</StyledButton>
					
						</Space>
					  );
					},
				  },
	
			] :
			[

				{
					title: "Username",
					dataIndex: "username",
					key: "username",
				},
				{
					title: "Amount Saved",
					dataIndex: "totalUserSavings",
					key: "totalUserSavings",
				},
				{
					title: "Amount Withdrawn",
					dataIndex: "totalAmountWithdrawn",
					key: "totalAmountWithdrawn",
				},
				{
					title: "No of Times Withdrawn",
					dataIndex: "timesWithdrawn",
					key: "timesWithdrawn",
				},
			
				{
					title: "Action",
					dataIndex: "action",
					key: "action",
					render: (text, record) => {
					  return (
						<Space size="middle">
							<StyledButton
							  type=""
							  buttonStyle="btn--primary--outline"
							  buttonSize="btn--small"
							  onClick={()=>{
								setSelectedRecord(record)
								setShowDetailModal(true)
								
							  }}
							>
							  See More
							</StyledButton>
					
						</Space>
					  );
					},
				  },
	
			],
		},

	];
	const cardData = [
		{
		  title:'Total User Enrolled',
		  amount: summaryData?.totalUsersEnrolled || 0,
		  icon:'/assets/images/investIcon.png'
		},	
		{
		  title:'Total Amount Earned',
		  amount:summaryData?.totalAmountEarned || 0,
		  icon:'/assets/images/investIcon.png'
		},	
		{
		  title:'Total Amount Invested',
		  amount:summaryData?.totalAmountInvested || 0,
		  icon:'/assets/images/investIcon.png'
		},	
		{
		  title:"Total User's Savings",
		  amount:summaryData?.totalUserSavings || 0, 
		  icon:'/assets/images/investIcon.png'
		  
		},	
	  
	  ]


	const onRefresh = () => {
		fetchPlanDetails()
	}

	useEffect(() => {
		onRefresh();
	}, []);


    const pages = [{ name: "Investment Management", link: "/admin/financials/investment" }];

	return (
		<>
			{planData &&
			<CreateInvestment
				showModal={openCreate}
				setShowModal={setOpenCreate}
				userData={planData}
				action={'edit'}
				plan_id={id}
				/>			
			}

			<PlanDetailModal
				userData={selectedRecord}
				showModal={showDetailModal}
				setShowModal={setShowDetailModal}
				type={''}
			/>
            <UserContainer>
                <NavBarLeft>
                <BreadCrumb pages={pages} current={orgData?.companyName} />
                </NavBarLeft>
            </UserContainer>

			<DeleteModal
				showModal={showDeleteModal}
				setShowModal={setDeleteModal}
				handleDelete={handleDeleteInvestmentPlan}
				title={'Plan'}
				type="plan"
			/>

			{/* <PromptModal
				handler={() => completePayment(currentEntry.invoiceNumber)}
				showModal={showPrompt}
				setShowModal={setPrompt}
				successMessage="Invoice has been marked as paid!"
				promptMessage="Are you sure you want to mark this invoice as paid?"
				buttonText="Mark"
				title={"Mark as paid"}
			/> */}

				{/* <Modal
					color={statuserror ? "red" : "#005700"}
					type="postAction"
					show={showSuccessModal}
					close={() => setSuccessModal(false)}
				>
					{!statuserror? "Invoice status set successfully" : 'Something went wrong. Pls try again!'}
				</Modal> */}


			{/* <Modal
				show={showSetStatus}
				close={() => setShowSetStatus(false)}
				width="65em"
			>
				<FlexBetween className="mb-7">
					<div className="flex gap-2 items-center">
						<img src="/assets/images/setstat.svg" alt="icon" />
						<p className="text-xl font-semibold">Set the Status for the Invoice</p>						
					</div>

					<div>
						<StyledButton
							raffle
							buttonStyle="btn--primary--outline"
							// buttonSize="btn--small"
							onClick={handleCancel}
							className=""
						>
								Cancel
						</StyledButton>						
					</div>

				</FlexBetween>


				<div className="grid grid-cols-2 gap-6">

					<FormInput
					type={'text'}
					label={'Organization'}
					// changeHandler={(e) => setValue(key, e.target.value)}
					errorMsg={''}
					value={orgData?.companyName}
					disabled={true}
				/>
					<FormInput
					type={'text'}
					label={'Date'}
					// changeHandler={(e) => setValue(key, e.target.value)}
					errorMsg={''}
					value={getMonthYear(selectedInvoice?.createdAt?.split('T')[0])}
					disabled={true}
				/>

				<div  className="relative">
					<div onClick={()=>setOpenPayStatus(true)}>
						<FormInput
						type={'text'}
						label={'Set Status'}
						placeholder={'Select Payment Status'}
						// changeHandler={(e) => setValue(key, e.target.value)}
						errorMsg={''}
						value={payStatus}
					/>							
					</div>


					{
						openPayStatus ?
						<ModalBackground>
							<div className='w-full cursor-pointer user-text h-[30px] text-left flex items-center pl-4 mt-1' onClick={()=>{
								setPayStatus('paid')
								setOpenPayStatus(!openPayStatus)
								console.log("it is done")
							}}>
								<span onClick={()=>{
								setOpenPayStatus(false)
							}}>Mark as Paid</span>
								<img src={`${payStatus === 'paid'}`} alt="" />
							
							</div>
							<hr />
							<div className='w-full cursor-pointer user-text h-[30px] text-left flex items-center pl-4'onClick={()=>{
								setPayStatus('partial payment')
							 	setOpenPayStatus(false)
							}}>
							Mark as Partial Payment
							</div>   

							
						</ModalBackground>	:	""					
					}
			
				</div>


				{
					payStatus === 'partial payment'&& 
						<FormInput
						type={'text'}
						label={`${payStatus === 'partial payment' ? 'Amount': 'Full Payment'} `}
						placeholder={'Input the amount paid'}
						changeHandler={(e) => setAmount(e.target.value)}
						errorMsg={''}
						value={amount}
					/>					
				}

				</div>

				<div className="w-full flex justify-center">
                					<StyledButton
										type=""
										buttonSize="btn--small"
										onClick={handleStatus}
										disabled={!payStatus || (payStatus === 'partial payment' && amount === "")}
									>
										Set Status
									</StyledButton>
				</div>
			</Modal> */}
			{/* <Modal
				show={showReceiptModal}
				close={() => setReceiptModal(false)}
				width="85em"
			>
				<FlexBetween className="mb-7">
					<p className="text-xl font-semibold">Organization Invoices</p>

					<div className="flex items-center gap-2">
						<StyledButton
							raffle
							buttonStyle="btn--primary--outline"
							buttonSize="btn--small"
							className="text-black"
							
						>
							<a download href={`${invoicebaseURL}/v2/invoice/generate/invoicepdf/${currentEntry?.invoiceNumber}`}>
								Download as PDF
							</a>
								
						</StyledButton>
					</div>


				</FlexBetween>
				<Invoice open={showReceiptModal} invoiceId={currentEntry?.invoiceNumber} ref={invoiceRef} />
			</Modal> */}

			{/* <Modal
				color={error ? "red" : "#005700"}
				type="postAction"
				show={showPostModal}
				close={() => setPostModal(false)}
			>
				{!error ? "Invoice status set successfully" : error}
			</Modal> */}

			{/* {showModal && (
				<InvoiceModal
				showModal={showModal}
				setShowModal={setShowModal}
				id={`org-${id}`}
				/>
			)} */}



            <div className="flex justify-between w-full">

                <div>
                	<h1 className=" font-Int text-[24px] font-[600]">{orgData?.companyName}</h1>
                </div>

					<div className="flex gap-2 items-center">
						<StyledButton
							buttonStyle="btn--gray--outline"
							buttonSize="btn--small"
							className="flex items-center gap-6 text-base relative"
							onClick={() => {
							setDeleteModal(true);
							}}
						>
							Delete Plan
						</StyledButton>
						<StyledButton
							buttonStyle="btn----outline"
							buttonSize="btn--small"
							className="flex justify-between items-center"
							onClick={() => setOpenCreate(true)}
						>
							Edit Plan
						</StyledButton>
					</div>



											
            </div>

                <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 container mt-2 mb-2">
					{
						cardData.map((i, id)=>{
							return(
							<PointCard key={id} title={i.title} amount={formatAmount(i.amount)} icon={i.icon} />
							)
						}) 

					}


                </div>


			<div className="flex flex-col gap-3">


				<WastePickerContainer>
					<Tabcontent
						data={data}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						onRefresh={onRefresh}
						onSwitch={handleSwitch}
					/>
				</WastePickerContainer>
			</div>
		</>
	);
};

export default PlanHistory;



